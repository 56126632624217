<template>
  <div>
    <b-modal
      id="assign-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AssignOrder')} : ${orderItems?.orderNumber}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          :label="$t('AssignTeam')"
        >
          <vue-select
            v-model="assignTeam"
            :placeholder="$t('SelectTeam')"
            :options="getTeamsNames"
            label="teamName"
            :reduce="(e) => e.teamNameId"
          />
        </b-form-group>
        <p
          v-if="buttonSubmited == true && assignTeam == null"
          style="color:red;margin-top: 10px;"
        >
          {{ $t('SelectTeam') }}
        </p>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
  },
  props: ['orderItems', 'assignmentTeamNameId'],
  data() {
    return {
      buttonSubmited: false,
      assignTeam: null,
    }
  },
  computed: {
    ...mapGetters([
      'getTeamsNames',
    ]),

  },
  watch: {
    assignmentTeamNameId(value) {
      if (value) {
        this.assignTeam = value
      }
    },
  },
  methods: {
    onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.buttonSubmited == true && this.assignTeam == null) {
          return
        }
        const object = {
          assignmentTeamNameId: this.assignTeam,
          orderItemIds: [this.orderItems.orderItemId],
        }
        this.$emit('assignTeamOne', object)
        this.$refs.modal.hide();
        this.onReset()
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
      this.assignTeam = this.assignmentTeamNameId
    },
  },
}
</script>
