<template>
  <div>
    <b-modal
      id="edit-manager"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeManager')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group :label="$t('AssignManager')">
          <vue-select
            v-model="newManager"
            :options="getUsersMultipleRoles"
            label="fullName"
            :reduce="(e) => e.userID"
            :placeholder="$t('AssignManager')"
            style="width: 100%;"
          />
        </b-form-group>
        <p
          v-if="buttonSubmited == true && newManager == null"
          style="color:red;margin-top: 10px;"
        >
          {{ $t('AssignManager') }}
        </p>
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
  },
  props: ['managerId'],
  data() {
    return {
      buttonSubmited: false,
      newManager: null,
    }
  },
  computed: {
    ...mapGetters([
      'getUsersMultipleRoles',
    ]),

  },
  watch: {
    managerId(value) {
      this.newManager = value
    },
  },
  methods: {
    onSubmit() {
      this.buttonSubmited = true
      try {
        if (this.buttonSubmited == true && this.newManager == null) {
          return;
        }
        this.$emit('editUser', this.newManager)
        this.$refs.modal.hide();
        this.onReset()
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset()
    },
    onReset() {
      this.buttonSubmited = false
    },
  },
}
</script>
